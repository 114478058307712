import React, { useState, useEffect, Component } from "react";
import './home.css';
import Topbar from './topbar.js';
import {
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { ThreeDots } from 'react-loader-spinner';
import { useAlert } from 'react-alert'
import GetUrl from "./geturl";
import AdSense from 'react-adsense';

<AdSense.Google
	client='ca-pub-6507622451339340'
	slot='7806394673'
/>

function Home() {

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const alert = useAlert()

  let navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    console.log(search);
    fetch(GetUrl() +'/search/' + search)
      .then((response) => response.json())
      .then((data) => {
        if(data.Id === undefined) {
          alert.show('There was an error finding the player you searched for.', {type: 'error' });;
          setLoading(false);
        }
        else{
          navigate("/player/" + data.Id);
        }
      }).catch(error => {
          alert.show('There was an error finding the player you searched for.', {type: 'error' });;
          setLoading(false);
      });
  }

  return (
    <div className="App">
      
      <Topbar active={0} />
      <div class="front">
        <h1 class="titlefront">MultiVersus Tracker</h1>
      </div>
      <div class="gap" />
      <form style={{ "display": (loading ? 'none' : 'inline') }} onSubmit={handleSubmit} visible={!loading}>
        <label>
          <input class="frontinput" placeholder='WB Games Name' type="text" name="name" onChange={e => setSearch(e.target.value)} />
        </label>
        <input class="frontsubmit" type="submit" value="SEARCH" />
      </form>
      <br/>
      <p class="undersearch" style={{ "display": (loading ? 'none' : 'inline') }}>Search for a player by their <span class="highlight">WB Games</span> name.</p>
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#d09100"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName="loader"
        class="loader"
        visible={loading}
      />
      <br/>
      <input onClick={() => navigate("/leaderboard")} class="frontsubmit" type="submit" style={{"width": "250px", "marginLeft": "0px", "marginTop": "20px"}} value="Go to Leaderboards" />

    </div>
  );
}

export default Home;