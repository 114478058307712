import './topbar.css';
import {
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";

function Topbar(props) {
  let navigate = useNavigate();

   
  const handleClick = (event) => {
    console.log("Hello World!")
    console.log(event)
  }

  return (
    <div class="topbar">
        <p class="topbartitle">MultiVersus Tracker</p>
        <p onClick={() => {navigate("/")}} class={(props.active === 0 ? 'active' : '') + " topbaritem"}>Home</p>
        <p onClick={() => {navigate("/")}} class={(props.active === 1 ? 'active' : '') + " topbaritem"}>Stats</p>
        <p onClick={() => {navigate("/leaderboard")}} class={(props.active === 2 ? 'active' : '') + " topbaritem"}>Leaderboards</p>
      </div>
  );
}

export default Topbar;
