import './Player.css';
import Topbar from './topbar.js';
import { useParams } from "react-router-dom";
import React, { useState, useEffect, Component } from "react";
import { ThreeDots } from 'react-loader-spinner';
import GetUrl from "./geturl";

var maps = {
  "/Game/Panda_Main/Maps/Map_Batcave/Map_Batcave.Map_Batcave": "Batcave",
  "/Game/Panda_Main/Maps/Map_Classic3Platform/Map_Classic3Platform_Small.Map_Classic3Platform_Small": "Classic 3 Platform (1 vs 1)",
  "/Game/Panda_Main/Maps/Map_Classic3Platform/Map_Classic3Platform_Large.Map_Classic3Platform_Large": "Classic 3 Platform",
  "/Game/Panda_Main/Maps/M011/Maps/LargeNoPlat/Map_M011_LargeNoPlat.Map_M011_LargeNoPlat": "Sky Arena",
  "/Game/Panda_Main/Maps/M011/Maps/Small/Map_M011_Small.Map_M011_Small": "Sky Arena Platforms (1 vs 1)",
  "/Game/Panda_Main/Maps/M011/Maps/SmallNoPlat/Map_M011_SmallNoPlat.Map_M011_SmallNoPlat": "Sky Arena (1 vs 1)",
  "/Game/Panda_Main/Maps/M011/Map_M011.Map_M011": "Sky Arena Platforms",
  "/Game/Panda_Main/Maps/ScoobyMansion/Map_ScoobyDoo.Map_ScoobyDoo": "Scooby's Haunted Mansion",
  "/Game/Panda_Main/Maps/ContainerMaps/TrainingContainer.TrainingContainer": "Training Room",
  "/Game/Panda_Main/Maps/TrainingRoomLarge/Map_TrainingRoomLarge.Map_TrainingRoomLarge": "Training Room",
  "/Game/Panda_Main/Maps/TrainingRoomSmall/Map_TrainingRoomSmall.Map_TrainingRoomSmall": "Training Room (1 vs 1)",
  "/Game/Panda_Main/Maps/Map_TreeHouseSmall/Map_TreeHouseSmall.Map_TreeHouseSmall": "Tree Fort (1 vs 1)",
  "/Game/Panda_Main/Maps/Map_TreeHouse/Map_TreeHouse.Map_TreeHouse": "Tree Fort",
  "/Game/Panda_Main/Maps/TrophyRoom/Map_TrophyRoom_2platforms.Map_TrophyRoom_2platforms": "Trophy's E.D.G.E. 2",
  "/Game/Panda_Main/Maps/TrophyRoom/Map_TrophyRoom_LargePlatform.Map_TrophyRoom_LargePlatform": "Trophy's E.D.G.E.",
};

var stringToColor = function(str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}

var getHighestChar = function(data) {
  var arr = Object.entries(data).map(([slug, values]) => ({ slug, ...values }));
  
  arr = arr.sort(function (a, b) {
  return b.Mastery.Level - a.Mastery.Level || b.Mastery.CurrentXP - a.Mastery.CurrentXP;
});

return arr[0].slug;
  }

function Match(props) {
  let params = useParams();

  let data = props.dta;
  if(data.draw){return null;}
  let ply = data.server_data.PlayerData.find(element => element.AccountId === props.acc);
  return (<div class="matchbox">
            <img class="matchboxchar" src={"\\images\\" + ply.CharacterSlug + ".png"} />
            <div class="matchmetabig">

              <div class="matchmeta">
                <p class="matchboxmode">{data.template.name}</p>
                {(data.win.indexOf(props.acc) > -1 ? (<p style={{ color: '#108ce0' }} class="matchboxtitle">Victory</p>) : (<p style={{color:'#d73145'}} class="matchboxtitle">Defeat</p>))}
                
              </div>
              <p class="matchboxstat loc">{maps[data.server_data.MapName]}</p>
            </div>
            <div class="matchboxstats">
              <p class="matchboxstat s">KOs:</p>
              <p class="matchboxstat l">{ply.Ringouts}</p>
            </div>

            <div class="matchboxstats">
              <p class="matchboxstat s">Damage:</p>
              <p class="matchboxstat l">{ply.DamageDone}</p>
            </div>
            <div class="matchboxstats">
              <p class="matchboxstat s">Deaths:</p>
              <p class="matchboxstat l">{ply.Deaths}</p>
            </div>


          </div>);
}



function Player() {
  let params = useParams();

  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(GetUrl() +'/player/' + params.playerid)
      .then((response) => response.json())
      .then((data2) => {
        setData(data2);

      });
  }, [])

  if (data === null) {
    return (
      <div class="App">
        <Topbar active={1} />

        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#d09100"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName="loader"
          class="loader"
        />
      </div>
    );
  }
  return (
    <div className="App" >
      <Topbar active={1} />
      <div class="topthing">

        <div class="character" style={{"background-color": stringToColor(data.responses[3].body.identity.alternate.wb_network[0].username)}}>
          <img src={"\\images\\" + getHighestChar(data.responses[3].body.server_data.Characters) + ".png"} />
        </div>

        <div>
          <p class="title">{data.responses[3].body.identity.alternate.wb_network[0].username}</p>

          <div  class="subbox">
            <p class="subtitle">Level: {data.responses[3].body.server_data.Level}</p>
            <p class="subtitle">Matches: {data.responses[0].body.server_data.matches_played}</p>
            <p class="subtitle">Damage: {data.responses[0].body.server_data.lifetime_damage}</p>
            <p class="subtitle">Ringouts: {data.responses[0].body.server_data.lifetime_ringouts}</p>
          </div>

        </div>

      </div>

      <div class="body">
        <div class="matches">
        {data.responses[4].body.matches.map((m) => <Match dta={m} acc={data.responses[0].body.account_id} />)}
        </div>
        <div class="stats">

        {data.responses[0].body["2v2"] !== undefined ? (<div class="statstack">
            <p class="stacktitle">Teams</p>
            <p class="stackstat">Rank: #{data.responses[2].body.rank}</p>
            <p class="stackstat">MMR: {Math.round(data.responses[2].body.score)}</p>
            <p class="stackstat">Record: {data.responses[0].body["2v2"].win} - {data.responses[0].body["2v2"].loss}</p>
            <p class="stackstat">Win Rate: {Math.round((data.responses[0].body["2v2"].win / (data.responses[0].body["2v2"].loss + data.responses[0].body["2v2"].win)) * 100)}%</p>
            <p class="stackstat">Longest streak: {data.responses[0].body["2v2"].longest_win_streak}</p>
          </div>) : (null)}


          

          {data.responses[0].body["1v1"] !== undefined ? (<div class="statstack">
            <p class="stacktitle">1V1</p>
            <p class="stackstat">Rank: #{data.responses[1].body.rank}</p>
            <p class="stackstat">MMR: {Math.round(data.responses[1].body.score)}</p>
            <p class="stackstat">Record: {data.responses[0].body["1v1"].win} - {data.responses[0].body["1v1"].loss}</p>
            <p class="stackstat">Win Rate: {Math.round((data.responses[0].body["1v1"].win / (data.responses[0].body["1v1"].loss + data.responses[0].body["1v1"].win)) * 100)}%</p>
            <p class="stackstat">Longest streak: {data.responses[0].body["1v1"].longest_win_streak}</p>
          </div>) : (null)}

          

          {data.responses[0].body["ffa"] !== undefined ? (
        <div class="statstack" style={{ "height": "150px" }}>
        <p class="stacktitle">FFA</p>
        <p class="stackstat">Record: {data.responses[0].body["ffa"].win} - {data.responses[0].body["ffa"].loss}</p>
        <p class="stackstat">Win Rate: {Math.round((data.responses[0].body["ffa"].win / (data.responses[0].body["ffa"].loss + data.responses[0].body["ffa"].win)) * 100)}%</p>
      </div>
      ) : (null)}
          

        </div>

      </div>

    </div>
  );
}

export default Player;
