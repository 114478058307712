import React, { useState, useEffect, Component } from "react";
import './leaderboard.css';
import Topbar from './topbar.js';
import { ThreeDots } from 'react-loader-spinner';
import {useParams,
    useNavigate,
    useLocation,
  } from "react-router-dom";
  import GetUrl from "./geturl";

function Leader(props) {
    let params = useParams();
  
    let data = props.dta;
    let page = props.page
    let navigate = useNavigate();
    
    return (<div class="leaderbox" onClick={() => navigate("/player/" + data.account.public_id)}>
        <p class="number leaderboxstat ll">{data.rank}.</p>
              <img class="leaderboxchar" src={"\\images\\" + data.profile['server_data.2v2shuffle.0.topRating.character'] + ".png"} />

                <p class="leaderboxtitle">{data.account["identity.alternate.wb_network"][0].username}</p>

                <p class="leaderboxstat sl">Score</p>
                <p class="leaderboxstat ll">{Math.round(data.score)}</p>
  
  
            </div>);
  }




function Leaderboard() {


  const [data, setData] = useState(null);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const pagesize = 20;

  useEffect(() => {
    fetch(GetUrl() +'/leaderboard/')
      .then((response) => response.json())
      .then((data2) => {
        setData(data2);

      });
  }, [])

  if (data === null) {
    return (
      <div class="App">
        <Topbar active={2} />

        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#d09100"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName="loader"
          class="loader"
        />
      </div>
    );
  }

  return (
    <div className="App">
      
      <Topbar active={2} />

      <p class="leaderboardtitle">Leaderboard</p>

      <input class={(page === 0 ? 'leaderactive' : 'leaderinactive')} type="submit" value="Teams" onClick={() => {setPage(0); setOffset(0);}} />
        <input class={(page === 1 ? 'leaderactive' : 'leaderinactive')} type="submit" value="1v1" onClick={() => {setPage(1); setOffset(0);}} />

        <div class="navbox">
            <input class="navbutton" type="submit" value="<" onClick={() => {if(offset > 0){setOffset(offset - pagesize)}}} />
            <p class="navtext">Page {(offset + pagesize)/(pagesize)} of {100/pagesize}</p>
            <input class="navbutton" type="submit" value=">" onClick={() => {if(offset < 80){setOffset(offset + pagesize)}}}/>
        </div>
      <div class="surround">
        {data.responses[page].body.leaders.slice(offset,offset+pagesize).map((m) => <Leader dta={m} page={page} />)}
      </div>

    </div>
  );
}

export default Leaderboard;